import React from 'react';
import { Link } from 'react-router-dom';
import LogoutButton from "../components/elements/LogoutButton";
import { useAuth0 } from '@auth0/auth0-react';

const Navigation = () => {
  const handleLogout = (event) => {
    event.preventDefault();
    logout(
      {
        logoutParams:
          { returnTo: `${window.location.protocol}//${window.location.host}/logout` }
      })
  };
  const { isAuthenticated, logout } = useAuth0();

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <div className="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar nav-child-indent flex-column" data-widget="treeview" role="menu">
            <li className="nav-item"><Link className="nav-link" to="/dashboard"><p>Dashboard</p></Link></li>
            <li className="nav-item"><Link className="nav-link" to="/collections">Collections</Link></li>
            <li className="nav-item"><Link className="nav-link" to="/datasets">Datasets</Link></li>
            <li className="nav-item"><Link className="nav-link" to="/public/datasets">Public Datasets</Link></li>
            <li className="nav-item"><Link className="nav-link" to="/public/collections">Public Collections</Link></li>
            {isAuthenticated &&
              <li className="nav-item"><Link className="nav-link" to="#" onClick={handleLogout}>Logout</Link></li>
            }
              {/* <li className="nav-item">
              {isAuthenticated &&
                <LogoutButton />}
            </li> */}
          </ul>
        </nav>
      </div>
    </aside>
      
  );
}

export default Navigation;