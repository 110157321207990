import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

function useApi() {
    const protocol = window.location.protocol
    const baseUrl = new URL(process.env.REACT_APP_REST_ENDPOINT, `${protocol}//` + process.env.REACT_APP_REST_DOMAIN)
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [accessToken, setAccessToken] = useState(undefined)

    const getToken = async () => {
        try {
            const t = await getAccessTokenSilently();
            setAccessToken(t)
            return t
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        // getToken();
    }, [isAuthenticated, user?.sub])

    async function authenticatedRequest(endpoint, options) {
        if(isLoading)
            throw new Error("App is still loading");

        var token = accessToken
        if(!isAuthenticated) {
            throw new Error("Not Authenticated")
        }
        if(token === undefined) {
            token = await getToken();
        }
        const authenticationHeader = `Bearer ${token}`
        if (!("headers" in options)) {
            options["headers"] = {}
        }
        options["headers"]["Authorization"] = authenticationHeader

        try {
            var res = fetch(endpoint, options)
        } catch(err) {
            console.log(err)
            throw new Error("Something went wrong")
        } finally {
            return res
        }
    }

    async function unauthenticatedRequest(endpoint, options) {
        try {
            var res = fetch(endpoint, options)
        } catch (err) {
            console.log(err)
            throw new Error("Something went wrong")
        } finally {
            return res
        }
    }

    async function getCollections() {
        const endpoint = new URL("collections", baseUrl);
        const options = {
            method: 'GET',
        };
        return authenticatedRequest(endpoint, options)
    }

    async function getPublicCollections() {
        const endpoint = new URL("collections/public", baseUrl);
        console.log(endpoint)
        const options = {
            method: 'GET',
        };
        return unauthenticatedRequest(endpoint, options)
    }

    async function getCollection(id) {
        const endpoint = new URL(`collections/${id}`, baseUrl);
        const options = {
            method: 'GET',
        };
        return authenticatedRequest(endpoint, options)
    }

    async function getCollectionContent(id) {
        const endpoint = new URL(`collections/${id}`, baseUrl);
        const options = {
            method: 'GET',
        };
        return authenticatedRequest(endpoint, options)
    }

    async function createCollection(
            name,
            modelIdentifier,
            indexType,
            metricType,
            indexParams) {
        const endpoint = new URL("collections", baseUrl);
        const data = {
            name: name,
            modelIdentifier: modelIdentifier,
            indexType: indexType,
            metricType: metricType,
            indexParams: indexParams
        };
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        return authenticatedRequest(endpoint, options)
    }

    async function createDataset(name) {
        const endpoint = new URL("datasets", baseUrl);
        const data = {
            name: name,
        };
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        return authenticatedRequest(endpoint, options)
    }

    async function requestBatchImport(bucket, accessKeyId, secretAccessKey, datasetId) {
        const endpoint = new URL(`datasets/${datasetId}/import/s3`, baseUrl)
        const data = {
            "bucket_name": bucket,
            "access_key_id": accessKeyId,
            "secret_access_key": secretAccessKey,
        }
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }
        return authenticatedRequest(endpoint, options)
    }

    async function queryCollectionByUpload(collectionId, file) {
        const endpoint = new URL(`collections/${collectionId}/query/upload`, baseUrl)
        const data = new FormData();
        data.append('file', file);

        const options = {
            method: 'POST',
            body: data,
        };

        return authenticatedRequest(endpoint, options)
    }

    async function queryCollectionByVectorId(collectionId, vectorId) {
        const endpoint = new URL(`collections/${collectionId}/query/${vectorId}`, baseUrl)
        // const data = {
        //     "vector_id": vectorId
        // }
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }
        return authenticatedRequest(endpoint, options)
    }

    async function getTempUrl(uuid) {
        const endpoint = new URL(`collections/files/${uuid}`, baseUrl)
        const options = {
            method: 'GET',
        };
        return authenticatedRequest(endpoint, options)
    }

    async function getTempUrls(uuids, collection_id) {
        const endpoint = new URL(`collections/${collection_id}/files/temporaryURIs`, baseUrl)
        const data = new FormData();
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({uuids: uuids})
        };
        return authenticatedRequest(endpoint, options)
    }

    async function deleteCollection(collectionId) {
        const endpoint = new URL(`collections/${collectionId}`, baseUrl)
        const options = {
            method: 'DELETE',
        };
        return authenticatedRequest(endpoint, options)
    }

    async function getDatasets() {
        const endpoint = new URL("datasets", baseUrl);
        const options = {
            method: 'GET',
        };
        return authenticatedRequest(endpoint, options)
    }

    async function getDataset(id) {
        const endpoint = new URL(`datasets/${id}`, baseUrl);
        const options = {
            method: 'GET',
        };
        return authenticatedRequest(endpoint, options)
    }

    async function getPublicDatasets() {
        const endpoint = new URL("datasets/public", baseUrl);
        const options = {
            method: 'GET',
        };
        return unauthenticatedRequest(endpoint, options)
    }

    async function importDataset(collectionId, datasetId) {
        const endpoint = new URL(`collections/${collectionId}/import/dataset`, baseUrl)
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ datasetId: datasetId })
        };
        return authenticatedRequest(endpoint, options)
    }

    return {
        createCollection: createCollection,
        getCollections: getCollections,
        deleteCollection: deleteCollection,
        getCollection: getCollection,
        requestBatchImport: requestBatchImport,
        queryCollectionByUpload: queryCollectionByUpload,
        queryCollectionByVectorId: queryCollectionByVectorId,
        getTempUrl: getTempUrl,
        getTempUrls: getTempUrls,
        getDatasets: getDatasets,
        getDataset: getDataset,
        createDataset: createDataset,
        getPublicDatasets: getPublicDatasets,
        getPublicCollections: getPublicCollections,
        importDataset: importDataset
    }
}

export default useApi